import axios from 'axios';
import url from "../constants/url";

export default function editCardHandler(e,loadedData,setModal,reloadList,modalDataOld) {
    e.preventDefault();
    console.log("submitted")
    console.log(loadedData)
    axios({
        method: 'put',
        url: url + "/upd_card",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'card': modalDataOld.card
        },
        data: {
            isGlobalUsed: loadedData.isGlobalUsed,
            card: loadedData.card,
            fullname: loadedData.fullname,
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
        });
}
